import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { startOfYear, endOfYear, subMonths, subYears, format, subDays } from 'date-fns';
import featureEnabled from '../../utils/featureFlags';
export var MIN_DATE = '2013-01-01';
export var TRAINING_SERIES = ['', 'rr', 'we', 'wms', 'ccrcsr', 'iw', 'bl', 'heawel_apple', 'her_finance', 'resrec_ua', 'resrec_vf', 'resrec_apple', 'resrec_apple_rp', 'resrec_refre_apple', 'resrec_plan', 'resrec_aurora', 'resrec', 'esr', 'esr_vf', 'esr_demo', 'grievance_agriculture_ra', 'move_rr', 'promo', 'scs_amazon', 'scoc', 'stitch', 'stitch_pilot', 'ohs', 'labor_relations', 'labor_relations_vf', 'child_labor_prevention_aurora', 'wasman_apple', 'child_labor_prevention_vf', 'quizrr_talks_hrdd_m_0'];
export var LEVELS = ['', 'basic', 'advanced'];
export var LANGUAGES = ['', 'am', 'ar', 'bd', 'cn', 'cr', 'cs', 'da', 'de', 'en', 'es', 'fr', 'hi', 'hu', 'in', 'it', 'ja', 'km', 'kn', 'ko', 'la', 'mr', 'ms', 'my', 'ne', 'nl', 'pt', 'si', 'sv', 'ta', 'te', 'tr', 'th', 'tl', 'tw', 'ur', 'vn', 'yue'];
export var GENDERS = ['', 'male', 'female', 'other'];
export var EMPLOYMENT_STATUS = ['', 'employed', 'unemployed'];
export var FACILITY_TYPES = ['', 'production_unit', 'labor_agent', 'service_provider'];
export var WORKTYPES = ['', 'worker', 'management', 'trainer', 'recruiter', 'other'];
export var PLATFORMS = ['', 'tablet', 'smartphone'
];
export var MARKETS = [{
        value: '',
        trainingSeries: TRAINING_SERIES,
        language: LANGUAGES
    }, {
        value: 'china',
        trainingSeries: ['', 'rr', 'we', 'wms', 'ccrcsr', 'iw', 'resrec_apple', 'resrec_apple_rp'],
        language: ['', 'en', 'cn', 'ja', 'ko', 'tw']
    }, {
        value: 'bangladesh',
        trainingSeries: ['', 'rr', 'we', 'bl', 'her_finance', 'stitch', 'stitch_pilot', 'esr_vf', 'ohs'],
        language: ['', 'en', 'bd', 'cn']
    }, {
        value: 'thailand',
        trainingSeries: ['', 'rr', 'resrec_vf', 'resrec_apple', 'resrec_apple_rp', 'resrec_plan', 'resrec', 'move_rr'],
        language: ['', 'en', 'th', 'tw', 'my', 'km', 'la', 'si', 'cn']
    }, {
        value: 'jordan',
        trainingSeries: ['', 'resrec_ua', 'resrec_vf'],
        language: ['', 'en', 'ar', 'th', 'tw', 'bd', 'hi', 'si', 'ne', 'my']
    }, {
        value: 'taiwan',
        trainingSeries: ['', 'resrec_vf', 'resrec_apple', 'resrec_apple_rp', 'resrec'],
        language: ['', 'en', 'tw', 'th', 'vn', 'in', 'tl']
    }, {
        value: 'mauritius',
        trainingSeries: ['', 'rr'],
        language: ['', 'en', 'bd', 'hi', 'cr']
    }, {
        value: 'vietnam',
        trainingSeries: ['', 'her_finance', 'resrec', 'ohs'],
        language: ['', 'en', 'vn', 'cn']
    }, {
        value: 'cambodia',
        trainingSeries: ['', 'her_finance', 'resrec', 'ohs', 'labor_relations_vf'],
        language: ['', 'en', 'my', 'tw', 'km', 'cn']
    }, {
        value: 'malaysia',
        trainingSeries: ['', 'resrec', 'resrec_ua', 'ohs'],
        language: ['', 'en', 'ms', 'my', 'ne', 'vn', 'bd', 'tl', 'si', 'km', 'in']
    }, {
        value: 'myanmar',
        trainingSeries: ['', 'resrec_apple', 'resrec'],
        language: ['', 'en', 'my']
    }, {
        value: 'philippines',
        trainingSeries: ['', 'resrec_apple'],
        language: ['']
    }, {
        value: 'egypt',
        trainingSeries: ['', 'her_finance', 'resrec'],
        language: ['', 'en', 'ar', 'si', 'bd', 'hi']
    }, {
        value: 'sweden',
        trainingSeries: ['', 'promo', 'esr_demo', 'ohs', 'resrec_apple', 'resrec_apple_rp'],
        language: ['', 'en', 'sv', 'ar', 'am']
    }, {
        value: 'india',
        trainingSeries: ['', 'esr', 'heawel_apple', 'resrec', 'resrec_apple', 'resrec_apple_rp', 'resrec_aurora', 'wasman_apple'],
        language: ['', 'en', 'bd', 'hi', 'ta', 'kn', 'te', 'mr']
    }, {
        value: 'mexico',
        trainingSeries: ['', 'resrec', 'resrec_aurora', 'ohs', 'resrec_apple_rp', 'labor_relations', 'labor_relations_vf', 'resrec_apple', 'resrec_refre_apple', 'grievance_agriculture_ra'],
        language: ['', 'en', 'es']
    }, {
        value: 'netherlands',
        trainingSeries: ['', 'resrec_apple', 'resrec_apple_rp'],
        language: ['', 'en', 'nl']
    }, {
        value: 'usa',
        trainingSeries: ['', 'resrec', 'resrec_apple', 'resrec_apple_rp', 'child_labor_prevention_aurora'],
        language: ['', 'en', 'es']
    }, {
        value: 'canada',
        trainingSeries: ['', 'esr', 'resrec', 'resrec_apple_rp'],
        language: ['', 'en', 'es']
    }, {
        value: 'indonesia',
        trainingSeries: ['', 'esr'],
        language: ['', 'en', 'in']
    }, {
        value: 'ksa',
        trainingSeries: ['', 'resrec_apple'],
        language: ['', 'en', 'ar', 'bd', 'hi']
    }, {
        value: 'singapore',
        trainingSeries: ['', 'resrec_apple', 'resrec_apple_rp'],
        language: ['', 'en', 'cn', 'ms', 'ta']
    }, {
        value: 'uae',
        trainingSeries: ['', 'resrec_apple', 'resrec_apple_rp'],
        language: ['', 'en', 'ar', 'bd', 'hi', 'mr']
    }, {
        value: 'argentina',
        trainingSeries: [],
        language: []
    }, {
        value: 'nicaragua',
        trainingSeries: [],
        language: []
    }, {
        value: 'laos',
        trainingSeries: [],
        language: []
    }, {
        value: 'afghanistan',
        trainingSeries: [],
        language: []
    }, {
        value: 'brazil',
        trainingSeries: ['', 'resrec_aurora', 'resrec_apple_rp', 'resrec_apple'],
        language: ['', 'en', 'pt']
    }, {
        value: 'australia',
        trainingSeries: ['', 'resrec_apple', 'resrec_apple_rp'],
        language: ['', 'en', 'hi', 'ta', 'es', 'pt']
    }, {
        value: 'japan',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en', 'ja']
    }, {
        value: 'south_korea',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en', 'ko']
    }, {
        value: 'turkey',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en', 'tr']
    }, {
        value: 'italy',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en', 'it']
    }, {
        value: 'austria',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en']
    }, {
        value: 'belgium',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en']
    }, {
        value: 'hong_kong',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en']
    }, {
        value: 'macau',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en', 'cn', 'ja', 'ko', 'tw']
    }, {
        value: 'switzerland',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en']
    }, {
        value: 'uk',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en']
    }, {
        value: 'france',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en', 'fr', 'ar']
    }, {
        value: 'germany',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en', 'de', 'ar']
    }, {
        value: 'spain',
        trainingSeries: ['', 'resrec_apple_rp'],
        language: ['', 'en']
    }];
export var TIME_PERIODS = [{
        labelKey: 'all_time',
        fromDate: '',
        untilDate: ''
    }, {
        labelKey: 'year_to_date',
        fromDate: format(startOfYear(new Date()), 'yyyy-MM-dd'),
        untilDate: format(new Date(), 'yyyy-MM-dd')
    }, {
        labelKey: 'previous_year',
        fromDate: format(startOfYear(subYears(new Date(), 1)), 'yyyy-MM-dd'),
        untilDate: format(endOfYear(subYears(new Date(), 1)), 'yyyy-MM-dd')
    }, {
        labelKey: 'last_12_months',
        fromDate: format(subMonths(new Date(), 12), 'yyyy-MM-dd'),
        untilDate: format(new Date(), 'yyyy-MM-dd')
    }, {
        labelKey: 'last_6_months',
        fromDate: format(subMonths(new Date(), 6), 'yyyy-MM-dd'),
        untilDate: format(new Date(), 'yyyy-MM-dd')
    }, {
        labelKey: 'last_3_months',
        fromDate: format(subMonths(new Date(), 3), 'yyyy-MM-dd'),
        untilDate: format(new Date(), 'yyyy-MM-dd')
    }, {
        labelKey: 'last_30_days',
        fromDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
        untilDate: format(new Date(), 'yyyy-MM-dd')
    }, {
        labelKey: 'last_7_days',
        fromDate: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
        untilDate: format(new Date(), 'yyyy-MM-dd')
    }];
export var SURVEY_TYPE = [{
        value: 'feedback',
        label: 'Post-training feedback'
    }];
export var GLOBAL_MARKET_CONTENT = {
    value: 'global',
    trainingSeries: ['', 'resrec', 'iom_toolkit_training', 'iom_toolkit_training_la', 'child_labor_prevention_vf', 'quizrr_talks_hrdd_m_0', 'resrec_refre_apple'].concat(_toConsumableArray(featureEnabled('allowTestProgram') ? ['sioux_manual_test'] : [])),
    language: ['', 'en', 'am', 'vn', 'cn', 'th', 'ja']
};
export var HIDDEN_FACILITY_TYPES = ['training_unit'];
