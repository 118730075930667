export var Actions = Object.freeze({
    customView: {
        edit: 'custom_view_edit',
        create: 'custom_view_create',
        "delete": 'custom_view_delete'
    },
    demoMode: {
        enable: 'demo_mode_enable'
    },
    filter: {
        edit: 'filter_edit'
    },
    tutorial: {
        trigger: 'tutorial_trigger',
        completed: 'tutorial_completed'
    },
    signupStep: {
        complete: 'sign_up_step_complete'
    },
    userManagement: {
        inviteByEmail: 'invite_by_email',
        copyInviteLink: 'copy_invite_link',
        reminder: 'send_reminder_email',
        revoke: 'revoke_member',
        remove: 'remove_member',
        changePermission: 'change_member_permission',
        downloadCertificate: 'download_player_certificate'
    },
    supplierManagement: {
        inviteByEmail: 'invite_supplier_by_email',
        copyInviteLink: 'copy_supplier_invite_link',
        addExistingInvitationCode: 'add_existing_invitation_code',
        addNonExistingInvitationCode: 'add_non_existing_invitation_code',
        copyTrainingLink: 'copy_supplier_training_link_without_registering',
        accept: 'accept_supplier',
        remove: 'remove_supplier',
        revoke: 'revoke_supplier',
        remind: 'remind_supplier',
        enableTraining: 'enable_supplier_training',
        disableTraining: 'disable_supplier_training'
    },
    becomeSupplier: {
        accept: 'accept_to_becoming_supplier',
        decline: 'decline_to_becoming_supplier'
    },
    trainingPlan: {
        edit: 'training_plan_edit',
        create: 'training_plan_create',
        "delete": 'training_plan_delete',
        showDetail: 'training_plan_show_detail'
    },
    list: {
        sort: 'list_sort',
        search: 'list_search',
        select: 'select_item'
    },
    trainingPerformance: {
        open: 'performance_by_question_open',
        close: 'performance_by_question_close'
    },
    trainingPerformanceKnowledgeChange: {
        open: 'knowledgechange_performance_by_question_open',
        close: 'knowledgechange_performance_by_question_close'
    },
    cookieConsent: {
        accept: 'cookie_consent_accept',
        decline: 'cookie_consent_decline'
    },
    qrCode: {
        edit: 'qr_code_edit'
    },
    subSupplierManagement: {
        inviteByEmail: 'invite_sub_supplier_by_email',
        copyInviteLink: 'copy_sub_supplier_invite_link',
        copyTrainingLink: 'copy_sub_supplier_training_link_without_registering',
        remove: 'remove_sub_supplier',
        revoke: 'revoke_sub_supplier',
        remind: 'remind_sub_supplier',
        enableTraining: 'enable_sub_supplier_training',
        disableTraining: 'disable_sub_supplier_training',
        enableLaborAgentUsage: 'enable_labor_agent_usage',
        disableLaborAgentUsage: 'disable_labor_agent_usage'
    },
    resource: {
        submitHomework: 'submit_homework'
    },
    changeLanguage: 'change_language',
    download: 'download',
    teamManagement: {
        editTeamSize: 'edit_team_size'
    },
    training: {
        startTraining: 'start_training',
        copyLink: 'copy_link',
        downloadTrainingQRCode: 'download_training_qr_code'
    },
    report: {
        generate_pdf: 'generate_pdf_report'
    },
    certificate: {
        generate: 'generate'
    }
});
export var Contexts = Object.freeze({
    filterPanel: {
        overview: 'filterPanelOverview',
        facility: 'filterPanelFacility',
        go: 'filterPanelFacilityGo'
    },
    customViews: 'customViews',
    guide: 'guide',
    signup: {
        player: 'playerSignup'
    },
    signin: 'signin',
    trainingPlans: {
        active: 'activeTrainingPlans',
        completed: 'completedTrainingPlans'
    },
    trainingPerformance: 'trainingPerformance',
    becomingSupplierRequest: 'becoming_supplier_request',
    supplierInvitation: 'supplier_invitation',
    teamManagement: 'teamManagement',
    qrCodes: 'qrCodes',
    insights: 'insights',
    resources: 'resources',
    trainingLibrary: 'trainingLibrary',
    brandColleaguesManageMent: 'brandColleaguesManagement',
    colleaguesManagement: 'colleaguesManagement',
    facilityTeamUserManagement: 'facilityTeamUserManagement',
    report: {
        pdf: 'PDFReport',
        csv: 'CSVReport'
    }
});
export var Categories = Object.freeze({
    trainingPlan: 'trainingPlan',
    trainingPerformance: 'trainingPerformance',
    signup: 'signup',
    userManagement: 'userManagement',
    supplierManagement: 'supplierManagement',
    supplierInvitation: 'supplierInvitation',
    subSupplierManagement: 'subSupplierManagement',
    inviteTypes: {
        emailMember: 'email_member',
        nonEmailPlayer: 'non_email_player',
        supplier: 'supplier',
        subSupplier: 'sub_supplier',
        authenticatedPlayer: 'authenticated_player',
        nonAuthenticatedPlayer: 'nonAuthenticated_player'
    },
    demoMode: 'demoMode',
    customViews: 'customViews',
    filters: 'filters',
    errors: 'errors',
    tutorial: 'tutorial',
    authentication: 'authentication',
    pageViews: 'pageViews',
    cookieConsent: 'cookieConsent',
    qrCodes: 'qrCodes',
    teamManagement: 'teamManagement',
    report: 'report',
    resource: 'resource',
    language: 'language',
    finishRegister: 'finishRegister',
    certificate: 'certificate',
    training: 'training'
});
